const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-dashboard",
        title: "Dashboard"
      },
      {
        icon: "UsersIcon",
        pageName: "side-menu-user-management",
        title: "User Management"
      },
      {
        icon: "InboxIcon",
        pageName: "side-menu-content-management",
        title: "Content Management"
      },
      {
        icon: "MessageSquareIcon",
        pageName: "side-menu-comment-management",
        title: "Comment Management"
      },
      {
        icon: "TrelloIcon",
        pageName: "agoda-management",
        title: "Agoda Management"
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu,
  menuOwner: state =>
    state.menu.filter(val => {
      return ["side-menu-dashboard"].includes(val.pageName);
    })
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
