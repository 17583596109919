<template>
  <div :id="modalId" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-5">
          <h2 class=" text-2xl font-bold">จัดการ Agoda Banner</h2>
          <div class="text-lg">บทความ {{ contentName }}</div>
          <div class=" border-b-2 mt-2 mb-4"></div>
          <form @submit.prevent="handleSubmitUpdate">
            <span class="block font-bold">สคริปต์ Agoda banner</span>
            <textarea
              v-model="agodaScript"
              class="mt-2 chat__box__input form-control h-40 resize-none border border-gray-200 p-2 shadow-none focus:ring-0"
            ></textarea>
            <div class="mt-4">
              <input
                :id="'checkbox' + modalId"
                v-model="isEnableBanner"
                type="checkbox"
                class="form-check-input border mr-2"
              />
              <label
                :for="'checkbox' + modalId"
                class="font-bold cursor-pointer"
              >
                เปิด Agoda banner สำหรับบทความนี้</label
              >
            </div>
            <span class="block "> </span>
            <div class="flex mt-4 justify-end">
              <button class="btn" type="button" @click="close">ยกเลิก</button>
              <button class="btn btn-primary ml-3" type="submit">
                อัปเดต
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs } from "vue";
import useAgodaManagement from "@/compositions/useAgodaManagement";

export default defineComponent({
  name: "UpdateAgodaBannerModal",
  setup() {
    const modalId =
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9);

    const { updateAgodaBanner } = useAgodaManagement();

    const contentName = ref("");

    const updateAgodaBannerPayload = reactive({
      id: 0,
      isEnableBanner: false,
      agodaScript: ""
    });

    const open = ({ id, link_agoda, on_banner, content_name }) => {
      updateAgodaBannerPayload.id = id;
      updateAgodaBannerPayload.isEnableBanner = on_banner;
      updateAgodaBannerPayload.agodaScript = link_agoda;
      contentName.value = content_name;
      cash("#" + modalId).modal("show");
    };
    const close = () => cash("#" + modalId).modal("hide");

    const handleSubmitUpdate = () => {
      console.log("update content!");
      updateAgodaBanner({
        id: updateAgodaBannerPayload.id,
        isEnableBanner: updateAgodaBannerPayload.isEnableBanner,
        agodaScript: updateAgodaBannerPayload.agodaScript
      });
      close();
    };

    return {
      ...toRefs(updateAgodaBannerPayload),
      contentName,
      modalId,
      open,
      close,
      handleSubmitUpdate
    };
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
