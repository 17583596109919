<template>
  <div>
    <div class="text-lg font-medium pt-2">
      <a href="javascript:;" @click="goBack"
        ><ChevronLeftIcon class="w-6 h-6 mr-1" />กลับ</a
      >
    </div>
    <div class="intro-y flex justify-between items-center my-3">
      <div class="flex items-center space-x-3">
        <h2 class="text-lg font-medium">ระงับการใช้งานแล้ว</h2>
        <div>
          <img
            alt="img"
            class="rounded-full object-cover object-center h-10 w-10"
            :src="getUserImg(user.User?.image_url)"
          />
        </div>
        <div>{{ user.User?.name }}</div>
      </div>
    </div>
    <div v-if="!items.length" class="intro-y box p-5 text-center text-2xl">
      <div>ไม่พบข้อมูล</div>
    </div>
    <div v-else class="intro-y box p-5">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="intro-y col-span-12 m-5"
      >
        <div class="box zoom-in p-5">
          <div class="flex flex-row justify-between items-center">
            <div>
              <div class="text-lg">
                {{ $h.formatDate(item.created_at, `[วันที่] D MMMM BBBB`) }}
              </div>
              <div class="text-lg">
                <span class=" font-bold">{{ getTypeText(item.type) }}</span>
                : {{ item.name }}
              </div>
              <div class="flex text-lg items-center space-x-3">
                <div>
                  ผู้รายงาน:
                </div>
                <div class="flex space-x-2">
                  <div>
                    <img
                      alt="img"
                      class="rounded-full object-cover object-center h-8 w-8"
                      :src="getUserImg(item.Reporter?.image_url)"
                    />
                  </div>
                  <div>
                    {{ item.Reporter?.name || "" }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a
                class="btn btn-primary whitespace-nowrap"
                :href="
                  `https://gottago-client-dev-knljyg54bq-as.a.run.app/feed/${item.ID}`
                "
                target="_blank"
                >ดูรายละเอียด</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as userService from "/src/services/user";
import defaultPeople from "@/assets/images/blank_user.png";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    let user = ref({
      follower: 0,
      User: {
        role_id: 1
      }
    });

    const items = ref([]);
    const getUserImg = image => {
      return image === "" ? defaultPeople : image;
    };

    const getTypeText = type => {
      switch (type) {
        case "Reply":
          return "รายงานตอบกลับความคิดเห็น";
        case "Comment":
          return "รายงานความคิดเห็น";
        case "User":
          return "รายงานผู้ใช้งาน";
        case "Content":
          return "รายงานบทความ";
        default:
          return "";
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    const fetch = async () => {
      const { id } = route.params;
      const res = await userService.getUserReported(id);
      const res_user = await userService.getUser(id);
      user.value = res_user.data.data.user_management;
      items.value = res.data.data.user_management;
    };

    onMounted(() => {
      fetch();
    });

    return {
      user,
      getUserImg,
      goBack,
      items,
      getTypeText
    };
  }
};
</script>

<style lang="scss" scoped></style>
