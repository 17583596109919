import axios from "axios";
import cookie from "js-cookie";
import { firebaseApp } from "./firebase";

let serviceUrl = "";
console.log("api environment :", process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
  serviceUrl = "https://api.gottago.me/";
} else {
  serviceUrl = "https://api.gottago.me/";
}

export const clientHost = "https://www.gottago.me";

export const noAccess = axios.create({
  baseURL: serviceUrl,
  headers: {
    Authorization: ``,
    "Content-type": "application/json; charset=utf-8"
  }
});

export const apiApp2 = axios.create({
  baseURL: serviceUrl,
  headers: {
    Authorization: `Bearer `
  }
});

let accessToken = cookie.get("access_token");

export const apiApp = axios.create({
  baseURL: serviceUrl,
  headers: {
    Authorization: `Bearer ${accessToken}`
  }
});

apiApp.interceptors.request.use(
  request => {
    let accessToken = cookie.get("access_token") || "";
    request.headers["Authorization"] = `Bearer ${accessToken}`;
    return request;
  },
  error => Promise.reject(error)
);

apiApp.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // const token = await firebaseApp.auth().currentUser.getIdToken() || ''
        firebaseApp.auth().onAuthStateChanged(async user => {
          if (user) {
            const token = await firebaseApp.auth().currentUser.getIdToken();
            console.log("get new token!!");
            if (token) {
              cookie.set("access_token", token);
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              apiApp.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${token}`;
              return apiApp(originalRequest);
            }
          } else {
            // No user is signed in.
          }
        });
      } catch (error) {
        console.log("error: ", error);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// apiApp.interceptors.request.use(
//   successfulReq => {
//     console.log("successfulReq: ", successfulReq);
//     return successfulReq;
//   },
//   error => Promise.reject(error)
// );
