<template>
  <div>
    <div class="text-lg font-medium py-2">
      <a href="javascript:;" @click="goBack"
        ><ChevronLeftIcon class="w-6 h-6 mr-1" />กลับ</a
      >
    </div>
    <div class="intro-y items-center mb-8">
      <h2 class="text-lg font-medium">Content Management</h2>
    </div>
    <!-- BEGIN: Content card -->
    <div v-for="(item, index) in data.Report" :key="index">
      <div class="grid grid-cols-6 justify-items-center intro-y box">
        <div class="w-full p-5 col-start-2 col-span-4 text-center">
          <div class="text-lg font-extrabold">
            {{
              data.Content.status_id == 3
                ? "บทความถูกลบแล้ว!!!"
                : item.report_status == 1
                ? "รายงานบทความ"
                : "แจ้งเตือนบทความแล้ว!!!"
            }}
          </div>
          <div class="mx-10 py-10 border-2 border-theme-2 dark:border-theme-2">
            <div class="text-lg text-left px-3  break-all">
              เหตุผลรายงานบทความ: {{ item.report_remark }}
            </div>
            <div
              class="flex space-x-4 flex-row justify-center items-center mt-3"
            >
              <div class="text-lg">ผู้รายงาน:</div>
              <div class="intro-y w-10 h-10 image-fit">
                <img
                  class="rounded-full"
                  src="@/assets/images/blank_user.png"
                />
              </div>
              <div class="text-base font-bold">{{ item.report_user }}</div>
            </div>
            <div class="flex justify-center mt-5  p-5">
              <FeedCard
                v-if="loading"
                :content="data.content"
                :readonly="true"
                :draft="false"
              >
                <template #action>
                  <div class="truncate text-lg font-semibold text-shadow">
                    {{ currencyFormat(data.Content.views) }} {{ "views" }}
                  </div>
                </template>
              </FeedCard>
            </div>
          </div>
          <div class="flex justify-center space-x-4 my-5">
            <button
              :disabled="data.Content.status_id > 1"
              class="flex w-56 btn items-center text-base"
              :class="{
                'btn-secondary': data.Content.status_id > 1,
                'btn-primary': data.Content.status_id == 1
              }"
              @click="
                report_id_select = item.report_id;
                modalOpen('#alert-modal');
              "
            >
              <AlertTriangleIcon class="w-4 h-4 mr-1" />แจ้งเตือนเจ้าของบทความ
            </button>
            <button
              :disabled="data.Content.status_id == 3"
              class="flex w-56 btn items-center text-lg"
              :class="{
                'btn-secondary': data.Content.status_id == 3,
                'btn-danger':
                  data.Content.status_id == 1 || data.Content.status_id == 2
              }"
              @click="modalOpen('#delete-confirmation-modal')"
            >
              <Trash2Icon class="w-4 h-4 mr-1" /> ลบบทความ
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content card -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      v-if="ModalState"
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">ยืนยันการลบบทความ</div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                data-dismiss="modal"
                @click="deleteContent"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
    <!-- BEGIN: Alert Modal -->
    <div
      v-if="ModalState"
      id="alert-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">แจ้งเตือนบทความ</div>
            </div>
            <div class="grid grid-cols-6 justify-items-center">
              <div class="col-start-2 col-span-4">
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    v-model="alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="บทความไม่เหมาะสม"
                  />
                  <label class="form-check-label" for="radio-switch-1"
                    >บทความไม่เหมาะสม</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    v-model="alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="บทความมีเนื้อหารุนแรง"
                  />
                  <label class="form-check-label" for="radio-switch-2"
                    >บทความมีเนื้อหารุนแรง</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-3"
                    v-model="alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="บทความมีเนื้อหาหยาบคาย"
                  />
                  <label class="form-check-label" for="radio-switch-3"
                    >บทความมีเนื้อหาหยาบคาย</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-4"
                    v-model="alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="other"
                  />
                  <label class="form-check-label" for="radio-switch-4"
                    >อื่นๆ</label
                  >
                </div>
                <div
                  class="mt-4 flex items-center border border-gray-200 dark:border-dark-5"
                >
                  <textarea
                    v-model="alert_comment"
                    class="chat__box__input form-control dark:bg-dark-3 h-20 resize-none border-transparent p-2 shadow-none focus:ring-0"
                    rows="1"
                    placeholder="เหตุผลการแจ้งเตือน..."
                    :disabled="!disabled"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="p-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
                @click="
                  () => {
                    alert_choice = 'บทความไม่เหมาะสม';
                    alert_comment = '';
                  }
                "
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                data-dismiss="modal"
                @click="alertContentOwner"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Alert Confirmation Modal -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FeedCard from "@/components/feed-card/Main.vue";
import * as contentService from "/src/services/content";
import { helper as $h } from "@/utils/helper";
import { useRoute } from "vue-router";

import cookie from "js-cookie";

export default defineComponent({
  components: {
    FeedCard
  },
  data() {
    return {
      data: {},
      loading: false,
      alert_choice: "บทความไม่เหมาะสม",
      alert_comment: "",
      disabled: false,
      report_id_select: 0,
      route: {}
    };
  },

  computed: {
    ModalState() {
      return this.data.Content
        ? this.data.Content.status_id == 3
          ? false
          : true
        : false;
    }
  },
  watch: {
    alert_choice(val) {
      console.log("val: ", val);
      if (val === "other") {
        this.disabled = true;
      } else {
        this.disabled = false;
        this.alert_comment = "";
      }
    }
  },
  mounted() {
    this.fetchContentDetail();
    this.route = useRoute();
  },
  methods: {
    async fetchContentDetail() {
      try {
        const id = this.$route.params.id;
        const response_content = await contentService.getContentDetail({
          content_id: +id
        });
        this.data = response_content.data.data.content_management;
        this.data.content = {
          content_name: this.data.Content.content_name,
          location_name: this.data.Content.location_name,
          image_url: this.data.Content.image_url,
          user: {
            first_name: this.data.name
          },
          status_id: this.data.status_id,
          id: this.data.Content.id
        };
        this.loading = true;
      } catch (error) {
        console.log(error);
      }
    },
    currencyFormat(input) {
      let output = $h.formatCurrency(input);
      return output == "" ? 0 : output;
    },
    modalOpen(tagName) {
      cash(tagName).modal("show");
    },
    modalClose(tagName) {
      cash(tagName).modal("hide");
    },
    async alertContentOwner() {
      console.log("alert content");
      try {
        let alert_data = {
          content_id: this.data.Content.id,
          remark:
            this.alert_choice != "other"
              ? this.alert_choice
              : this.alert_comment,
          to_user: this.data.Content.user_uid,
          report_id: this.report_id_select
        };
        await contentService.alertContentOwner(alert_data);
        this.fetchContentDetail();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteContent() {
      console.log("delete content");
      try {
        await contentService.updateContent(this.data.Content.id);
        this.fetchContentDetail();
      } catch (error) {
        console.log(error);
      }
    },
    goBack() {
      cookie.set("back_page", true);
      this.$router.go(-1);
    }
  }
});
</script>
