import { reactive } from "vue";
import * as services from "/src/services/agodaManagement";

const initState = {
  contents: [],
  totalContents: 0
};

export default function useAgodaManagement() {
  const state = reactive(initState);

  const getContents = async params => {
    const result = await services.getContents(params);
    const { Content, total } = result.data.data;
    state.contents = [...(Content || [])];
    state.totalContents = total;
  };

  const updateAgodaBanner = async ({ id, isEnableBanner, agodaScript }) => {
    const result = await services.updateAgodaBanner({
      id,
      isEnableBanner,
      agodaScript
    });
    const { Content } = result.data.data;
    if (Content) {
      const contentIndex = state.contents.findIndex(e => e.id === Content.id);

      state.contents = [
        ...state.contents.slice(0, contentIndex),
        Content,
        ...state.contents.slice(contentIndex + 1)
      ];
    }
  };

  return {
    state,
    getContents,
    updateAgodaBanner
  };
}
