import { apiApp } from "/src/config/api";

const endpoint = "management/dashboard";

const getOverallDashboard = () => apiApp.get(endpoint);
const getContentPopular = data =>
  apiApp.post(`${endpoint}/contents/popular`, data);
const getFollowPopular = data =>
  apiApp.post(`${endpoint}/follows/popular`, data);
const getMoodsPopular = data => apiApp.post(`${endpoint}/moods/popular`, data);
const getTagsPopular = data => apiApp.post(`${endpoint}/tags/popular`, data);
const getLatestPublishContent = () => apiApp.get(endpoint + "/contents/last");

export {
  getOverallDashboard,
  getContentPopular,
  getFollowPopular,
  getMoodsPopular,
  getTagsPopular,
  getLatestPublishContent
};
