import { reactive } from "vue";
import * as services from "/src/services/content";

const initState = {
  contents: [],
  numberOfAllContents: 0,
  numberOfManagedContents: 0,
  numberOfNonManageContents: 0
};

export default function useContents() {
  const state = reactive(initState);

  const getContents = async ({ payload, params }) => {
    const result = await services.getContentList({ payload, params });
    state.contents = [...(result?.data?.data?.content_management || [])];
  };

  const getContentInfo = async payload => {
    const {
      data: {
        data: {
          content_management_info: { all, managed, non_manage }
        }
      }
    } = await services.getContentInfo(payload);
    state.numberOfAllContents = all;
    state.numberOfManagedContents = managed;
    state.numberOfNonManageContents = non_manage;
  };

  return {
    state,
    getContents,
    getContentInfo
  };
}
