<template>
  <div>
    <div class="text-lg font-medium pt-2">
      <a href="javascript:;" @click="goBack"
        ><ChevronLeftIcon class="w-6 h-6 mr-1" />กลับ</a
      >
    </div>
    <div class="intro-y flex justify-between items-center my-3">
      <h2 class="text-lg font-medium">ข้อมูลผู้ใช้งาน</h2>
      <div class="flex space-x-3">
        <div class="btn  btn-warning text-white " @click="setModal">
          ส่งแจ้งเตือน
        </div>
        <div class="btn btn-danger " @click="confirmSuspend">
          ระงับการใช้งาน
        </div>
      </div>
    </div>
    <div class="intro-y box p-5">
      <div class="grid grid-cols-2 divide-x-2">
        <div class="p-3 flex flex-col justify-center  items-center ">
          <!-- <img src="" alt=""> -->
          <div class="p-3">
            <img
              alt="img"
              class="rounded-full object-cover object-center h-40 w-40"
              :src="getUserImg(user.User?.image_url)"
            />
          </div>
          <div class="flex space-x-10  text-2xl">
            <div class="text-center">
              <div>Follower</div>
              <div class="text-4xl font-semibold">
                {{ $h.kFormatter(user.follower) }}
              </div>
            </div>
            <div class="text-center">
              <div>Following</div>
              <div class="text-4xl font-semibold">
                {{ $h.kFormatter(user.following) }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div class="intro-y p-5 space-y-3">
            <div class="flex items-center">
              <label class=" w-32">Username</label>
              <input
                :value="user.User?.email"
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <div class="flex items-center">
              <label class=" w-32">ชื่อ/นามสกุล</label>
              <input
                :value="`${user.User?.first_name || user.User?.name || ''}`"
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <div class="flex items-center">
              <label class=" w-32">เบอร์โทรศัพท์</label>
              <input
                :value="user.User?.phone_number || '-'"
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <div class="flex items-center">
              <label class=" w-32">วันเกิด</label>
              <input
                :value="
                  user.User?.birth_date
                    ? $h.formatDate(user.User?.birth_date, 'DD/MM/YYYY')
                    : '-'
                "
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <div class="flex items-center">
              <label class=" w-32">เพศ</label>
              <select
                :value="user.User?.gender_type_id"
                disabled
                class="form-control w-full"
              >
                <option :value="1">ชาย</option>
                <option :value="2">หญิง</option>
                <option :value="3">อื่นๆ</option>
              </select>
            </div>
            <div class="flex items-center">
              <label class=" w-32">จังหวัด</label>
              <input
                :value="user.User?.province?.name_th || '-'"
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <div class="flex items-center">
              <label class=" w-32">อีเมล์</label>
              <input
                :value="user.User?.email"
                disabled
                type="text"
                class="form-control w-full"
              />
            </div>
            <!-- <div class="flex items-center">
              <label class=" w-32">ลงทะเบียนผ่าน</label>
              <div class="w-full relative">
                <div class="inline-block bg-gray-200 px-3 py-1  ">
                  Facebook
                </div>
              </div>
            </div> -->
            <!-- <div class="flex items-center">
              <label class=" w-32">วันที่เข้าใช้ล่าสุด</label>
              <div class="w-full relative">
                <div class="px-3">
                  Facebook
                </div>
              </div>
            </div> -->
            <div class="flex items-center">
              <label class=" w-32">สถานะการใช้งาน</label>
              <div class="w-full relative">
                <div class="px-3 text-lg font-semibold">
                  <div>{{ getStatusText(user.status) }}</div>
                  <div class=" text-red-600 text-xs">
                    โดนรายงาน 10 ครั้ง ระงับการใช้งาน
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center">
              <label class=" w-32">ประเภทบัญชี</label>
              <select v-model="user.User.role_id" class="form-control w-full">
                <option :value="1">User</option>
                <option :value="2">Owner</option>
                <option :value="3" disabled>Super Admin</option>
              </select>
            </div>

            <div class="py-3">
              <div class="btn btn-primary block " @click="update">อัพเดท</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">
      <div class="text-xl font-semibold">บทความทั้งหมด</div>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th class="border-b-4  whitespace-nowrap">ชื่อบทความ</th>
              <th class="border-b-4  whitespace-nowrap">
                วันที่อัพเดท
              </th>
              <th class="border-b-4  whitespace-nowrap">
                ยอดรับชม
              </th>
              <th class="border-b-4  whitespace-nowrap">
                ยอดรับถูกใจ
              </th>
              <th class="border-b-4  whitespace-nowrap">
                ความคิดเห็น
              </th>
              <th class="border-b-4  whitespace-nowrap">
                ยอดแชร์
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(val, index) in user.ContentList" :key="index">
              <td class="border-b ">{{ val.content_name }}</td>
              <td class="border-b whitespace-nowrap">
                {{ $h.formatDate(val.updated_at, "D/MM/BB") }}
              </td>
              <td class="border-b whitespace-nowrap">
                {{ $h.kFormatter(val.view) }} views
              </td>
              <td class="border-b whitespace-nowrap">
                {{ $h.kFormatter(val.like) }} likes
              </td>
              <td class="border-b whitespace-nowrap">
                {{ $h.kFormatter(val.comment) }} comments
              </td>
              <td class="border-b whitespace-nowrap">
                {{ $h.kFormatter(val.share) }} shares
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div id="alert-modal-user" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">แจ้งเตือนผู้ใช้งาน</div>
            </div>
            <div class="grid grid-cols-6 justify-items-center">
              <div class="col-start-2 col-span-4">
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานมีความก่อกวน"
                  />
                  <label class="form-check-label" for="radio-switch-1"
                    >ผู้ใช้งานมีความก่อกวน</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานมีความยุยงให้เกิดความขัดแย้ง"
                  />
                  <label class="form-check-label" for="radio-switch-2"
                    >ผู้ใช้งานมีความยุยงให้เกิดความขัดแย้ง</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-3"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานสนับสนุนความรุนแรง"
                  />
                  <label class="form-check-label" for="radio-switch-3"
                    >ผู้ใช้งานสนับสนุนความรุนแรง</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-4"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="other"
                  />
                  <label class="form-check-label" for="radio-switch-4"
                    >อื่นๆ</label
                  >
                </div>
                <div class="mt-4 flex items-center border border-gray-200 ">
                  <textarea
                    v-model="state.alert_comment"
                    class="chat__box__input form-control dark:bg-dark-3 h-20 resize-none border-transparent p-2 shadow-none focus:ring-0"
                    rows="1"
                    placeholder="เหตุผลการแจ้งเตือน..."
                    :disabled="disabled"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="p-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
                @click="
                  () => {
                    state.alert_choice = 'บทความไม่เหมาะสม';
                    state.alert_comment = '';
                  }
                "
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24  "
                data-dismiss="modal"
                @click="confirmReportUser"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="confirm-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-2xl mt-5">
                คุณต้องการระงับการใช้งานผู้ใช้ใช่หรือไม่
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                data-dismiss="modal"
                @click="suspendUserHandle"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import defaultPeople from "@/assets/images/blank_user.png";
import * as userService from "/src/services/user";
import * as adminService from "/src/services/admin";
import Swal from "sweetalert2";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    let user = ref({
      follower: 0,
      User: {
        role_id: 1
      }
    });
    const disabled = ref(true);
    const state = reactive({
      alert_comment: "",
      alert_choice: "ผู้ใช้งานมีความก่อกวน"
    });

    watch(state, val => {
      if (val.alert_choice !== "other") {
        disabled.value = true;
        val.alert_comment = "";
      } else {
        disabled.value = false;
      }
    });

    const setModal = () => {
      cash("#alert-modal-user").modal("show");
    };

    const confirmReportUser = async () => {
      try {
        const { id } = route.params;
        const data = {
          remark: state.alert_choice ? state.alert_choice : state.alert_comment,
          Type: "User"
        };
        await userService.reportUser(id, data);

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "แจ้งเตือนสำเร็จ"
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.response.data.error) {
          const errorMessage = error.response.data.error.error_message;
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            title: errorMessage
          });
        }
      }
    };

    const confirmSuspend = () => {
      cash("#confirm-modal").modal("show");
    };

    const suspendUserHandle = async () => {
      try {
        const { id } = route.params;
        const data = {
          user_uid: id,
          status: 2
        };
        await userService.susppendUser(data);

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "ระงับผู้ใช้งานสำเร็จ"
        });
        router.go(-1);
      } catch (error) {
        console.log("error: ", error);
        if (error.response.data.error) {
          const errorMessage = error.response.data.error.error_message;
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            title: errorMessage
          });
        }
      }
    };

    const getStatusText = status => {
      switch (status) {
        case "normal":
          return "ปกติ";
        case "reported":
          return "ถูกรายงาน";
        case "suspension":
          return "ระงับการใช้งาน";
        default:
          return status;
      }
    };

    const getUserImg = image => {
      return image == "" ? defaultPeople : image;
    };

    const goBack = () => {
      router.go(-1);
    };

    const update = async () => {
      try {
        const { id } = route.params;
        const data = {
          user_uid: id,
          role_id: +user.value.User.role_id || 1
        };

        await adminService.upgradeRole(data);

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "อัพเดทสำเร็จ"
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.response.data.error) {
          const errorMessage = error.response.data.error.error_message;
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            title: errorMessage
          });
        }
      }
    };

    const fetch = async () => {
      const { id } = route.params;
      const res = await userService.getUser(id);
      const { user_management } = res.data.data;
      console.log("res.data.data: ", res.data.data);
      user.value = user_management;
    };

    onMounted(() => {
      fetch();
    });
    return {
      user,
      getUserImg,
      goBack,
      update,
      state,
      setModal,
      confirmReportUser,
      suspendUserHandle,
      confirmSuspend,
      disabled,
      getStatusText
    };
  }
};
</script>

<style lang="scss" scoped></style>
