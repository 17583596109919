import { reactive } from "vue";
import * as services from "/src/services/dashboard";

const initState = {
  popularUserList: []
};

export default function usePopularUser() {
  const state = reactive(initState);

  const getPopularUser = async params => {
    const result = await services.getFollowPopular(params);
    state.popularUserList = [...(result?.data?.data?.dashboard || [])];
  };

  return {
    state,
    getPopularUser
  };
}
