<template>
  <div
    class="feed-card bg-gray-800 text-white flex flex-col justify-between object-contain bg-cover w-full"
    :style="{ backgroundImage: `url(${getCover})` }"
  >
    <a
      :href="getContentLink"
      target="_blank"
      class="h-full flex flex-col justify-between"
    >
      <div class="flex p-5 space-x-3">
        <div class="w-full text-2xl font-semibold text-shadow">
          <div class="leading-none text-height-2 px-1 text-left">
            {{ content.content_name }}
          </div>
          <div
            v-if="content.location_name"
            class="flex pt-2 -ml-2 items-center space-x-1"
          >
            <img src="@/assets/images/pin.svg" class="w-10" />
            <div class="text-base text-height-1 px-1 p-1">
              {{ content.location_name }}
            </div>
          </div>
        </div>
      </div>
    </a>

    <div class="person-container p-3 flex justify-between items-center">
      <a
        :href="getContentLink"
        target="_blank"
        class="flex truncate space-x-3 items-center w-auto"
      >
        <div
          class="flex-shrink-0 h-10 w-10 rounded-full bg-center object-center object-cover bg-cover"
          :style="{ backgroundImage: `url(${getUserImg})` }"
        ></div>
        <div class="truncate font-semibold text-shadow w-auto">
          {{ getUserName }}
        </div>
      </a>
      <div class="flex-none items-center">
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import defaultCover from "@/assets/images/mock_images/Image_181.png";
import defaultPeople from "@/assets/images/blank_user.png";
import { helper as $h } from "@/utils/helper";

export default {
  name: "FeedCard",
  props: {
    content: { type: [Array, Object], default: () => {} }
  },
  computed: {
    isDraft() {
      return this.draft ? true : false;
    },
    isReadonly() {
      return this.readonly ? true : false;
    },
    getCover() {
      return $h.isValidUrl(this.content.image_url)
        ? this.content.image_url
        : defaultCover;
    },
    getUserImg() {
      if (this.content && this.content.user && this.content.user.image_url) {
        return this.content.user.image_url;
      }
      return defaultPeople;
    },
    getUserName() {
      if (this.content && this.content.user && this.content.user.first_name) {
        return this.content.user.first_name;
      }
      if (this.user && this.user.first_name) {
        return this.user.first_name;
      }
      return "";
    },
    getContentLink() {
      return (
        "https://gottago-client-dev-knljyg54bq-as.a.run.app/feed/" +
        this.content.id
      );
    }
  },
  methods: {
    currencyFormat(input) {
      let output = $h.formatCurrency(input);
      return output == "" ? 0 : output;
    }
  }
};
</script>

<style lang="scss" scoped>
.feed-card {
  height: 275px;
  border-radius: 20px;
  /* background-image: url("~assets/mock_images/Image_181.png"); */
  background-position: center center;
  background-repeat: no-repeat;
}
.person-container {
  background: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  border-radius: 20px;
}
</style>
