<template>
  <div>
    <header class="intro-y mt-8">
      <div class="flex items-baseline">
        <h2 class="text-lg font-medium">Agoda Management</h2>
        <div class="w-56 relative text-gray-700 dark:text-gray-300 ml-auto">
          <input
            v-model="enteredSearch"
            type="text"
            class="form-control border-transparent bg-whit pr-10 placeholder-theme-13"
            placeholder="ค้นหา"
            @input="handleParamsChange"
          />
          <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
        </div>
        <select
          v-model="selectedFilter"
          class="form-select w-56 ml-4"
          @change="handleParamsChange"
        >
          <option value="latest">เรียงตามบทความล่าสุด</option>
          <option value="most likes">เรียงตามยอดไลค์สูงสุด</option>
          <option value="most views">เรียงตามยอดเข้าชมสูงสุด</option>
          <option value="most shares">เรียงตามยอดแชร์สูงสุด</option>
        </select>
      </div>
    </header>
    <div class="w-full intro-y box p-5 mt-8">
      <template v-if="contents.length > 0">
        <AgodaContentsListTable
          :contents="contents"
          :pagination="pagination"
          :type="selectedFilter"
          @update="handleBannerUpdate"
        />
        <div class="flex justify-between items-baseline mt-4">
          <div>
            <span>Page Size</span>
            <select
              v-model="pagination.pageSize"
              class="form-select ml-3 w-20"
              @change="handleParamsChange"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <Pagination :pagination="pagination" @change="handlePageChange" />
        </div>
      </template>
      <template v-else>
        <div class="text-lg text-center my-4 ">
          ไม่พบบทความ
        </div>
      </template>
    </div>
    <UpdateAgodaBannerModal ref="updateAgodaBannerModal" />
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from "vue";
import useAgodaManagement from "@/compositions/useAgodaManagement";
import usePagination from "@/compositions/usePagination";
import AgodaContentsListTable from "@/components/agoda-management/AgodaContentsListTable.vue";
import Pagination from "@/components/common/Pagination.vue";
import UpdateAgodaBannerModal from "@/components/agoda-management/UpdateAgodaBannerModal.vue";
import _ from "lodash";

export default defineComponent({
  components: {
    AgodaContentsListTable,
    Pagination,
    UpdateAgodaBannerModal
  },
  setup() {
    const enteredSearch = ref("");
    const selectedFilter = ref("latest");
    const updateAgodaBannerModal = ref(null);

    const { state: contents, getContents } = useAgodaManagement();

    const { state: pagination, handlePagination } = usePagination();

    const updateContent = () => {
      const params = {
        page: pagination.currentPage,
        size: pagination.pageSize,
        filter: selectedFilter.value,
        search: enteredSearch.value
      };
      getContents(params);
    };

    watch(contents, (_, newVal) => {
      pagination.totalItems = newVal.totalContents;
      handlePagination();
    });

    const handleParamsChange = _.debounce(() => {
      pagination.currentPage = 1;
      updateContent();
    }, 500);

    const handlePageChange = pageNumber => {
      pagination.currentPage = pageNumber;
      updateContent();
    };

    const handleBannerUpdate = content => {
      updateAgodaBannerModal.value.open(content);
    };

    updateContent();
    return {
      ...toRefs(contents),
      pagination,
      enteredSearch,
      selectedFilter,
      updateAgodaBannerModal,
      handleParamsChange,
      handlePageChange,
      handleBannerUpdate
    };
  }
});
</script>
