import * as types from "./mutation-types";

const state = () => {
  return {
    darkMode: false,
    date: ""
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  date: state => state.date
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  setDate({ commit }, date) {
    commit(types.SET_DATE, { date });
  }
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  [types.SET_DATE](state, { date }) {
    state.date = date;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
