import { apiApp } from "/src/config/api";

const endpoint = "management/user/";

const getUserList = params => apiApp.get(endpoint, { params });
const getUser = id => apiApp.get(endpoint + id);
const getUserReported = id => apiApp.get(`${endpoint + id}/reported`);
const reportUser = (id, data) => apiApp.post(`${endpoint}${id}/alert`, data);
const susppendUser = data => apiApp.patch(`${endpoint}suspension`, data);

export { getUserList, getUser, reportUser, susppendUser, getUserReported };
