<template>
  <div>
    <div class="px-5 pb-8 text-center">
      <button type="button" class="btn btn-danger w-24" @click="userLogout()">
        Logout
        <!-- <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" /> -->
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import cookie from "js-cookie";

export default defineComponent({
  mounted() {
    cookie.remove("access_token");
    this.$router.push({ path: "/login" });
  }
});
</script>
