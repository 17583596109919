import firebase from "firebase/app";
import "firebase/auth";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = process.env.firebase_config || {
  apiKey: "AIzaSyCXKlKJvZuy0TpDw5GKESiLlroGcAVCXlk",
  authDomain: "gottago-prod.firebaseapp.com",
  databaseURL: "https://gottago-prod.firebaseio.com",
  projectId: "gottago-prod",
  storageBucket: "gottago-prod.appspot.com",
  messagingSenderId: "620747896284",
  appId: "1:620747896284:web:bc48a8a63c594379328b19",
  measurementId: "G-1HSTNVGVLT"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const install = app => {
  app.config.globalProperties.$firebase = () => firebaseApp;
};

export { install as default, firebaseApp };
