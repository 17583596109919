import { reactive } from "vue";
import * as services from "/src/services/dashboard";

const initState = {
  latestPublishContentList: []
};

export default function useLatestPublishContent() {
  const state = reactive(initState);

  const getLatestPublishContent = async () => {
    const result = await services.getLatestPublishContent();
    state.latestPublishContentList = [...(result?.data?.data?.dashboard || [])];
  };

  return {
    state,
    getLatestPublishContent
  };
}
