<template>
  <div>
    <ul class="pagination">
      <button :disabled="isFirstPage" @click="changePage(1)">
        <ChevronsLeftIcon />
      </button>
      <button :disabled="isFirstPage" @click="changePage(currentPage - 1)">
        <ChevronLeftIcon />
      </button>
      <li v-for="page in pages" :key="page">
        <button
          :class="[{ active: currentPage === page }]"
          @click="changePage(page)"
        >
          {{ page }}
        </button>
      </li>
      <button :disabled="isLastPage" @click="changePage(currentPage + 1)">
        <ChevronRightIcon />
      </button>
      <button :disabled="isLastPage" @click="changePage(totalPages)">
        <ChevronsRightIcon />
      </button>
    </ul>
  </div>
</template>
<script>
import { defineComponent, toRefs, computed } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const changePage = pageNumber => {
      emit("change", pageNumber);
    };

    const isFirstPage = computed(() => {
      return props.pagination.currentPage === 1;
    });

    const isLastPage = computed(() => {
      return props.pagination.currentPage === props.pagination.totalPages;
    });
    return {
      ...toRefs(props.pagination),
      isFirstPage,
      isLastPage,
      changePage
    };
  }
});
</script>

<style scoped>
.pagination button {
  border-radius: 0.375rem;
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  margin-left: 8px;
  outline: none;
}

.pagination button.active {
  background-color: rgba(237, 242, 247);
  font-weight: bold;
}
.pagination button:hover {
  background-color: rgba(226, 232, 240);
}
</style>
