import { apiApp } from "/src/config/api";

const endpoint = "management/comment";

const getCommentList = ({ payload, params }) =>
  apiApp.post(endpoint, payload, { params });
const getCommentDetail = data => apiApp.post(`${endpoint}/${data}`);
const updateCommentStatus = data => apiApp.patch(`${endpoint}/`, data);
const getCommentInfo = payload => apiApp.post(endpoint + "/info", payload);

export {
  getCommentList,
  getCommentDetail,
  updateCommentStatus,
  getCommentInfo
};
