<template>
  <div>
    <!-- BEGIN: Header -->
    <div
      class="intro-y flex flex-col justify-start sm:flex-row items-center mt-8"
    >
      <h2 class="text-lg font-medium">รายงานความคิดเห็นทั้งหมด</h2>
      <div class="relative w-60 mx-5">
        <div
          class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
        >
          <CalendarIcon class="w-4 h-4" />
        </div>
        <Litepicker
          v-model="date"
          :options="calendarOptions"
          disable-default
          class="form-control pl-12 w-50 block mx-auto"
          @change="updateComments"
        />
      </div>
      <button
        class="btn btn-secondary"
        @click="
          () => {
            date = '';
            updateComments();
          }
        "
      >
        รีเซ็ต
      </button>
    </div>
    <!-- END: Header -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y pr-1 w-4/5 mt-4">
      <div class="tab-box box p-2">
        <div class="chat__tabs nav nav-tabs justify-start" role="tablist">
          <a
            id="normal-tab"
            data-toggle="tab"
            data-target="#normal"
            href="javascript:;"
            class="flex-1 py-2 text-center active"
            role="tab"
            aria-controls="normal"
            aria-selected="true"
            @click="handleStatus('all')"
            >ความคิดเห็นทั้งหมด ({{ numberOfAllComments }})
          </a>
          <a
            id="report-tab"
            data-toggle="tab"
            data-target="#report"
            href="javascript:;"
            class="flex-1 py-2 text-center"
            role="tab"
            aria-controls="report"
            aria-selected="false"
            @click="handleStatus('non-manage')"
            >ความคิดเห็นที่ยังไม่ได้จัดการ ({{ numberOfManagedComments }})</a
          >
          <a
            id="ban-tab"
            data-toggle="tab"
            data-target="#ban"
            href="javascript:;"
            class="flex-1 py-2 text-center"
            role="tab"
            aria-controls="ban"
            aria-selected="false"
            @click="handleStatus('managed')"
            >ความคิดเห็นที่จัดการแล้ว ({{ numberOfNonManageComments }})</a
          >
        </div>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
      <div class="intro-y box p-5">
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tabulatorRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Confirmation Modal -->
    <div id="confirm-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">{{ modal.message }}</div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                data-dismiss="modal"
                @click="onModalConfirm"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Confirmation Modal -->
  </div>
</template>
<script>
import { defineComponent, reactive, ref, toRefs } from "vue";
import useComments from "@/compositions/useComments";
import Tabulator from "tabulator-tables";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import feather from "feather-icons";

export default defineComponent({
  setup() {
    const calendarOptions = reactive({
      autoApply: false,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      showWeekNumbers: true,
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true
      }
    });

    const date = ref("");

    const columns = reactive([
      {
        title: "วันที่",
        field: "created_at",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        formatter(cell) {
          return getDateText(cell.getValue());
        }
      },
      {
        title: "<p class=text-center>ความคิดเห็น</p>",
        field: "detail_comment",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        formatter: cell => {
          return `<div class="text">
              ${cell.getValue()}
            </div>`;
        },
        cellClick: function(_, cell) {
          const { detail_comment } = cell.getData();
          getModalText("ความคิดเห็น", detail_comment);
        }
      },
      {
        title: "ผู้รายงาน",
        maxWidth: 170,
        field: "reporter",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        formatter(cell) {
          return `<div class="text">
              ${cell.getValue()}
            </div>`;
        }
      },
      {
        title: "เหตุผล",
        field: "report_remark",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        formatter(cell) {
          return `<div class="text">
               ${cell.getValue()}
            </div>`;
        },
        cellClick: function(_, cell) {
          const { report_remark } = cell.getData();
          getModalText("เหตุผล", report_remark);
        }
      },
      {
        title: "ตัวเลือก",
        field: "report_id",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false,
        columns: [
          {
            title: "",
            field: "report_id",
            headerSort: false,
            maxWidth: 50,
            formatter: cell => {
              const status = cell.getData().status;
              return `<button
                  class="btn ${
                    status == 4 || status == 1 ? "btn-danger" : "btn-secondary"
                  } w-full"
                >
                  ลบ
                </button>`;
            },
            cellClick: function(_, cell) {
              if (cell.getData().status == 1) {
                openModal(4, "ยืนยันการลบความคิดเห็น", cell.getValue());
              }
            }
          },
          {
            title: "",
            field: "report_id",
            headerSort: false,
            maxWidth: 50,
            formatter: cell => {
              const status = cell.getData().status;
              return `<button
                  class="btn ${
                    status == 5 || status == 1 ? "btn-warning" : "btn-secondary"
                  } w-full"
                >
                  ซ่อน
                </button>`;
            },
            cellClick: function(_, cell) {
              if (cell.getData().status == 1) {
                openModal(5, "ซ่อนความคิดเห็น", cell.getValue());
              }
            }
          },
          {
            title: "",
            field: "report_id",
            headerSort: false,
            maxWidth: 100,
            formatter: cell => {
              const status = cell.getData().status;
              return `<button
                  class="btn ${
                    status == 3 || status == 1 ? "btn-dark" : "btn-secondary"
                  } w-full"
                >
                  ไม่สนใจ
                </button>`;
            },
            cellClick: function(_, cell) {
              if (cell.getData().status == 1) {
                openModal(3, "ไม่สนใจรายการนี้", cell.getValue());
              }
            }
          },
          {
            title: "",
            field: "content_id",
            headerSort: false,
            maxWidth: 100,
            formatter: cell => {
              return `<a class="btn btn-primary w-full" href='https://www.gottago.me/feed/${
                cell.getData().content_id
              }' target="_blank">
                    view post
                    </a>`;
            }
          }
        ]
      }
    ]);

    const modal = reactive({
      status: 0,
      messsage: "",
      reportId: 0
    });

    const {
      state: commentsState,
      getComments,
      updateCommentStatus,
      getCommentInfo
    } = useComments();

    const tabulator = ref();
    const tabulatorRef = ref();
    // status = 'all' | 'non-manage' | 'managed'
    const commentStatus = ref("all");

    const getDateText = date => {
      return date
        ? dayjs(date).format("DD MMMM YYYY")
        : dayjs().format("DD MMMM YYYY");
    };

    const getModalText = (title, text) => {
      Swal.fire({
        title,
        html: `
            <div class="break-all">
              ${text}
            </div>
            `,
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#fe6f61"
      });
    };

    const openModal = (mode, message, reportId) => {
      console.log("reportId? ", reportId);
      cash("#confirm-modal").modal("show");
      modal.status = mode;
      modal.message = message;
      modal.reportId = reportId;
    };

    const onModalConfirm = async () => {
      try {
        await updateCommentStatus({
          reportId: modal.reportId,
          status: modal.status
        });
        updateComments();
      } catch (error) {
        console.log(error);
      }
    };

    const getDateRange = dateString => {
      if (!dateString) return {};
      const [beg_date, end_date] = dateString.split("-");
      return {
        beg_date: dayjs(beg_date).format("YYYY-MM-DD") + "T00:00:00Z",
        end_date: dayjs(end_date).format("YYYY-MM-DD") + "T23:59:59Z"
      };
    };

    const setTabulator = (tb, tbRef) => {
      tb.value = new Tabulator(tbRef.value, {
        data: commentsState.comments,
        printAsHtml: true,
        printStyled: true,
        layout: "fitColumns",
        responsiveLayout: "hide",
        placeholder: "No matching records found",
        columns: columns,
        renderComplete() {
          feather.replace({
            "stroke-width": 1.5
          });
        }
      });

      addRedrawTabulatorWhenResize();
    };

    const addRedrawTabulatorWhenResize = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5
        });
      });
    };

    const updateComments = async () => {
      const payload = { ...getDateRange(date.value) };
      const params = { page: 1, size: 100, manage: commentStatus.value };
      await getComments({ payload, params });
      getCommentInfo(payload);
      setTabulator(tabulator, tabulatorRef);
    };

    const handleStatus = status => {
      commentStatus.value = status;
      updateComments();
    };

    updateComments();

    return {
      ...toRefs(commentsState),
      getComments,
      calendarOptions,
      date,
      modal,
      tabulator,
      tabulatorRef,
      columns,
      onModalConfirm,
      updateComments,
      handleStatus
    };
  }
});
</script>
<style scoped>
.tab-box {
  border-color: transparent;
  border-radius: 0.375rem 0.375rem 0 0;
  position: relative;
  top: 8px;
}
</style>
