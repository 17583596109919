import { apiApp } from "/src/config/api";

const endpoint = "management/content";

const getContentList = ({ payload, params }) =>
  apiApp.post(endpoint, payload, { params });
const getContentDetail = data => apiApp.post(`${endpoint}/`, data);
const updateContent = id => apiApp.patch(`${endpoint}/${id}`);
const alertContentOwner = data => apiApp.post(`${endpoint}/alert`, data);
const getContentInfo = payload => apiApp.post(endpoint + "/info", payload);

export {
  getContentList,
  getContentDetail,
  updateContent,
  alertContentOwner,
  getContentInfo
};
