import { reactive } from "vue";
import * as services from "/src/services/comment";

const initState = {
  comments: [],
  numberOfAllComments: 0,
  numberOfManagedComments: 0,
  numberOfNonManageComments: 0
};

export default function useComments() {
  const state = reactive(initState);

  const getComments = async ({ payload, params }) => {
    const result = await services.getCommentList({ payload, params });
    state.comments = [...(result?.data?.data?.comment_mange || [])];
    return result;
  };

  const updateCommentStatus = async ({ reportId, status }) => {
    const result = await services.updateCommentStatus({
      report_id: reportId,
      status
    });
    return result;
  };

  const getCommentInfo = async payload => {
    const {
      data: {
        data: {
          comment_manage: { all, managed, non_manage }
        }
      }
    } = await services.getCommentInfo(payload);
    state.numberOfAllComments = all;
    state.numberOfManagedComments = managed;
    state.numberOfNonManageComments = non_manage;
  };

  return {
    state,
    getComments,
    updateCommentStatus,
    getCommentInfo
  };
}
