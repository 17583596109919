import { apiApp } from "/src/config/api";
const endpoint = "management/agoda";

const getContents = params => apiApp.get(endpoint, { params });
const updateAgodaBanner = ({ id, isEnableBanner, agodaScript }) =>
  apiApp.patch(endpoint, {
    content_id: id,
    on_banner: isEnableBanner,
    link_agoda: agodaScript
  });

export { getContents, updateAgodaBanner };
