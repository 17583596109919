<template>
  <div class="table-responsive w-full" v-bind="$attrs">
    <table class="w-full">
      <thead>
        <tr>
          <th class="w-1/12 text-left">รหัส</th>
          <th class="w-6/12 text-left">ชื่อบทความ</th>
          <th class="w-3/12 text-left">{{ typeHeadline }}</th>
          <th class="w-1/12">สถานะ</th>
          <th class="w-1/12">จัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="content in contents" :key="content.id">
          <td>{{ content.id }}</td>
          <td>
            <span
              class="font-bold cursor-pointer"
              @click="goContentPage(content.id)"
            >
              {{ content.content_name }}</span
            >
          </td>
          <td>
            <template v-if="type === 'latest'">
              {{ dayjs(content.created_at).format("DD-MMM-YYYY HH:mm:ss") }}
            </template>
            <template v-else-if="type === 'most likes'">
              {{ helper.numberWithCommas(content.likes) }}
            </template>
            <template v-else-if="type === 'most views'">
              {{ helper.numberWithCommas(content.views) }}
            </template>
            <template v-else-if="type === 'most shares'">
              {{ helper.numberWithCommas(content.shares) }}
            </template>
          </td>
          <td>
            <template v-if="content.on_banner">
              <div class="status open">
                เปิด
              </div>
            </template>
            <template v-else>
              <div class="status close">
                ปิด
              </div>
            </template>
          </td>
          <td class="text-center">
            <button @click="emit('update', content)">
              <EditIcon />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { clientHost } from "@/config/api";
import { helper } from "@/utils/helper";
import dayjs from "dayjs";

export default defineComponent({
  name: "AgodaContentsListTable",
  props: {
    contents: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const typeHeadline = computed(() => {
      return {
        latest: "วันที่สร้างบทความ",
        "most likes": "ยอดไลค์",
        "most views": "ยอดเข้าชม",
        "most shares": "ยอดแชร์"
      }[props.type];
    });

    const goContentPage = id => {
      open(clientHost + "/feed/" + id, "_blank");
    };

    return {
      typeHeadline,
      dayjs,
      helper,
      emit,
      goContentPage
    };
  }
});
</script>
<style scoped>
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
thead {
  border-color: rgba(226, 232, 240);
  border-top-width: 1px;
  border-bottom-width: 1px;
}
th {
  padding: 12px 20px;
  font-weight: normal;
}
td {
  padding: 12px 20px;
}

.status {
  text-align: center;
  border-radius: 16px;
  font-weight: bold;
  padding: 4px 16px;
}
.status.open {
  background-color: #abf1cd;
  color: #21732d;
}

.status.close {
  background-color: #f5b1b1;
  color: #542525;
}

button {
  outline: none !important;
}
</style>
