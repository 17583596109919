import { reactive } from "vue";
import * as services from "/src/services/dashboard";

const initState = {
  popularContentList: []
};

export default function usePopularContent() {
  const state = reactive(initState);

  const getPopularContent = async params => {
    const result = await services.getContentPopular(params);
    state.popularContentList = [...(result?.data?.data?.dashboard || [])];
  };

  return {
    state,
    getPopularContent
  };
}
