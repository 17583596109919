import { reactive } from "vue";
import * as services from "/src/services/dashboard";

const initState = {
  popularMoodList: []
};

export default function usePopularMood() {
  const state = reactive(initState);

  const getPopularMood = async params => {
    const result = await services.getMoodsPopular(params);
    state.popularMoodList = [...(result?.data?.data?.dashboard || [])];
  };

  return {
    state,
    getPopularMood
  };
}
