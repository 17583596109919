<template>
  <div>
    <div
      class="intro-y flex flex-col justify-between sm:flex-row items-center mt-8"
    >
      <h2 class="text-lg font-medium">User Management</h2>
      <div class="flex flex-row space-x-3">
        <select
          id="tabulator-html-filter-field"
          v-model="filter_user"
          class="form-select w-40"
        >
          <option value="follow">ผู้ติดตามสูงสุด</option>
          <option value="like">ยอดกดไลค์สูงสุด</option>
          <option value="share">ยอดแชร์สูงสุด</option>
          <option value="view">ยอดรับชมสูงสุด</option>
        </select>
        <div class="w-56 relative text-gray-700 dark:text-gray-300">
          <input
            v-model="search_user"
            type="text"
            class="form-control border-transparent bg-gray-200 pr-10 placeholder-theme-13"
            placeholder="ค้นหา"
          />
          <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
        </div>
        <button class="btn btn-primary" @click="searchHandle">ค้นหา</button>
        <button class="btn btn-secondary" @click="resetHandle">รีเซ็ต</button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
      <div class="intro-y pr-1 w-1/2">
        <div class="tab-box box p-2">
          <div class="chat__tabs nav nav-tabs justify-start" role="tablist">
            <a
              id="normal-tab"
              data-toggle="tab"
              data-target="#normal"
              href="javascript:;"
              class="flex-1 py-2 text-center active"
              role="tab"
              aria-controls="normal"
              aria-selected="true"
              @click="handleType('normal')"
              >ผู้ใช้งานทั้งหมด</a
            >
            <a
              id="report-tab"
              data-toggle="tab"
              data-target="#report"
              href="javascript:;"
              class="flex-1 py-2 text-center"
              role="tab"
              aria-controls="report"
              aria-selected="false"
              @click="handleType('report')"
              >ผู้ใช้งานถูกรายงาน</a
            >
            <a
              id="ban-tab"
              data-toggle="tab"
              data-target="#ban"
              href="javascript:;"
              class="flex-1 py-2 text-center"
              role="tab"
              aria-controls="ban"
              aria-selected="false"
              @click="handleType('ban')"
              >ผู้ใช้งานถูกระงับการใช้งาน</a
            >
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          id="normal"
          class="tab-pane active"
          role="tabpanel"
          aria-labelledby="normal-tab"
        >
          <div class="intro-y box p-5">
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulatorNomal"
                ref="tableRefNormal"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
        <div
          id="report"
          class="tab-pane"
          role="tabpanel"
          aria-labelledby="report-tab"
        >
          <div class="intro-y box p-5 mt-1">
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulatorReport"
                ref="tableRefReport"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
        <div
          id="ban"
          class="tab-pane"
          role="tabpanel"
          aria-labelledby="ban-tab"
        >
          <div class="intro-y box p-5 mt-1">
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulatorBan"
                ref="tableRefBan"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import feather from "feather-icons";
import Tabulator from "tabulator-tables";
import defaultPeople from "@/assets/images/blank_user.png";
import * as userService from "/src/services/user";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const state = ref("normal");

    const page = ref(1);

    const filter_user = ref("");
    const search_user = ref();
    const tableRefNormal = ref();
    const tabulatorNormal = ref();
    const tableRefReport = ref();
    const tableRefBan = ref();
    const router = useRouter();

    const columns = [
      {
        title: "ลำดับ",
        width: 80,
        field: "no",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false
      },
      {
        title: "username",
        minWidth: 200,
        field: "name_with_image",
        hozAlign: "start",
        vertAlign: "middle",
        headerHozAlign: "center",
        formatter(cell) {
          return `<div class="flex space-x-4 lg:justify-start items-center">
                    <div class="intro-x w-10 h-10 image-fit">
                      <img alt="img" class="rounded-full" src="${getUserImg(
                        cell.getData().image_url
                      )}">
                    </div>
                    <div>
                    ${cell.getData().name}
                    </div>
                </div>`;
        },
        headerSort: false
      },
      {
        title: "ผู้ติดตาม",
        minWidth: 100,
        field: "follow",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false
      },
      {
        title: "จำนวนบทความ",
        minWidth: 100,
        field: "content",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        headerSort: false
      },
      {
        title: "สถานะ",
        minWidth: 100,
        field: "status",
        responsive: 1,
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        formatter(cell) {
          const isNormal = cell.getData().status == "normal";
          let text = "";
          switch (cell.getData().status) {
            case "reported":
              text = "ถูกรายงาน";
              break;
            case "suspension":
              text = "ระงับการใช้งาน";
              break;
            default:
              text = "ปกติ";
              break;
          }
          return `<div class="flex items-center lg:justify-center ${
            isNormal ? "text-theme-9" : "text-theme-6"
          }">
                <i data-feather="${
                  isNormal ? "check-square" : "alert-triangle"
                }" class="w-4 h-4 mr-2"></i> ${text}
              </div>`;
        },
        headerSort: false
      },
      {
        title: "ตัวเลือก",
        minWidth: 200,
        field: "actions",
        hozAlign: "center",
        vertAlign: "middle",
        headerHozAlign: "center",
        formatter() {
          return `<div>
                  <div
                    class="btn btn-primary"
                    >ดูรายละเอียด</div
                  >
              </div>`;
        },
        cellClick: function(e, cell) {
          const { uid } = cell.getData();
          let type = "detail";
          switch (state.value) {
            case "report":
              type = "reported";
              break;
            case "ban":
              type = "suspension";
              break;
            default:
              break;
          }

          console.log("type: ", type);
          router.push(`/user-management/${uid}/${type}`);
        },
        headerSort: false
      }
    ];

    function getUserImg(image) {
      return image === "" ? defaultPeople : image;
    }

    const searchHandle = () => {
      handleType(state.value);
    };

    const resetHandle = () => {
      filter_user.value = "";
      search_user.value = "";
      handleType(state.value);
    };

    const initTabulator = (tb, tbRef, type) => {
      try {
        tb.value = new Tabulator(tbRef.value, {
          ajaxURL: `url`,
          ajaxRequestFunc: async (url, config, params) => {
            page.value = params.page;
            params.type = type;

            if (filter_user.value) {
              params.filter = filter_user.value;
            }

            if (search_user.value) {
              params.search = search_user.value;
            }

            return new Promise(function(resolve, reject) {
              try {
                userService.getUserList(params).then(data => {
                  resolve(data.data);
                });
              } catch (error) {
                console.log("error: ", error);
                reject();
              }
            });
          },
          ajaxResponse: function(url, params, response) {
            let data_return = {
              last_page: response.data.lastpage,
              data: response.data.user_management.map((x, index) => {
                // const sum = index + 1;
                const sum = page.value > 1 ? 10 * (page.value - 1) : 1;
                x.no = sum + (page.value > 1 ? index + 1 : index);
                return x;
              })
            };
            return data_return;
            //return the tableData property of a response json object
          },
          ajaxURLGenerator: function(url, config, params) {
            return `${url}/management/user/?offset=${(params.page - 1) *
              params.size}&size=${params.size}&type=${type}`;
          },
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          pagination: "remote",
          paginationSize: 10,
          paginationSizeSelector: [10, 20, 30, 40],
          layout: "fitColumns",
          responsiveLayout: "collapse",
          placeholder: "No matching records found",
          columns: columns,
          renderComplete() {
            feather.replace({
              "stroke-width": 1.5
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulatorNormal.value.redraw();
        feather.replace({
          "stroke-width": 1.5
        });
      });
    };

    const handleType = async (type = "normal") => {
      state.value = type;

      switch (type) {
        case "report":
          await initTabulator(tabulatorNormal, tableRefReport, "reported");
          break;
        case "ban":
          await initTabulator(tabulatorNormal, tableRefBan, "suspension");
          break;
        default:
          await initTabulator(tabulatorNormal, tableRefNormal, type);
          break;
      }
    };

    onMounted(async () => {
      handleType();
      reInitOnResizeWindow();
    });

    return {
      tableRefNormal,
      tableRefReport,
      tableRefBan,
      tabulatorNormal,
      filter_user,
      search_user,
      handleType,
      searchHandle,
      resetHandle
    };
  }
});
</script>

<style lang="scss" scoped>
.tab-box {
  border-color: transparent;
  border-radius: 0.375rem 0.375rem 0 0;
  position: relative;
  top: 8px;
}

.tabulator
  .tabulator-header
  .tabulator-headers
  .tabulator-col
  .tabulator-col-content {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: "center" !important;
}
</style>
