<template>
  <div>
    <div
      class="intro-y flex flex-col justify-start sm:flex-row items-center mt-8"
    >
      <h2 class="text-lg font-medium">Content Management</h2>
      <div class="relative w-60 mx-5">
        <div
          class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
        >
          <CalendarIcon class="w-4 h-4" />
        </div>
        <Litepicker
          v-model="date"
          :options="calendarOptions"
          disable-default
          class="form-control pl-12 w-50 block mx-auto"
          @change="updateContents"
        />
      </div>
      <button
        class="btn btn-secondary"
        @click="
          () => {
            date = '';
            updateContents();
          }
        "
      >
        รีเซ็ต
      </button>
    </div>
    <!-- BEGIN: Content card -->
    <div class="intro-y pr-1 w-4/5 mt-4 mb-2">
      <div class="tab-box box p-2">
        <div class="chat__tabs nav nav-tabs justify-start" role="tablist">
          <a
            id="normal-tab"
            data-toggle="tab"
            data-target="#normal"
            href="javascript:;"
            class="flex-1 py-2 text-center active"
            role="tab"
            aria-controls="normal"
            aria-selected="true"
            @click="handleStatus('all')"
            >บทความทั้งหมด ({{ numberOfAllContents }})
          </a>
          <a
            id="report-tab"
            data-toggle="tab"
            data-target="#report"
            href="javascript:;"
            class="flex-1 py-2 text-center"
            role="tab"
            aria-controls="report"
            aria-selected="false"
            @click="handleStatus('non-manage')"
            >บทความที่ยังไม่ได้จัดการ ({{ numberOfManagedContents }})</a
          >
          <a
            id="ban-tab"
            data-toggle="tab"
            data-target="#ban"
            href="javascript:;"
            class="flex-1 py-2 text-center"
            role="tab"
            aria-controls="ban"
            aria-selected="false"
            @click="handleStatus('managed')"
            >บทความที่จัดการแล้ว ({{ numberOfNonManageContents }})</a
          >
        </div>
      </div>
    </div>

    <div
      v-for="(item, index) in contents"
      :key="index"
      class="intro-y col-span-12 mb-5"
    >
      <div class="box zoom-in p-5">
        <div class="flex flex-row justify-between items-center">
          <div>
            <div class="text-lg">
              {{ dateText(item.beg_date) }}
            </div>
            <div class="text-lg">Content: {{ item.content_name }}</div>
            <div class="text-lg">
              จำนวนการถูกรายงาน: {{ numberWithCommas(item.content_report) }}
            </div>
          </div>
          <div>
            <router-link
              :to="`/content-management/${item.content_id}`"
              class="btn btn-primary whitespace-nowrap"
              >ดูรายละเอียด</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content card -->
  </div>
</template>
<script>
import { defineComponent, reactive, ref, toRefs } from "vue";
import useContents from "@/compositions/useContents";
import dayjs from "dayjs";
import { helper } from "@/utils/helper";

export default defineComponent({
  setup() {
    const calendarOptions = reactive({
      autoApply: false,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      showWeekNumbers: true,
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true
      }
    });

    const date = ref("");
    // status = 'all' | 'non-manage' | 'managed'
    const commentStatus = ref("all");

    const { state: contentsState, getContents, getContentInfo } = useContents();

    const getDateRange = dateString => {
      if (!dateString) return {};
      const [beg_date, end_date] = dateString.split("-");
      return {
        beg_date: dayjs(beg_date).format("YYYY-MM-DD") + "T00:00:00Z",
        end_date: dayjs(end_date).format("YYYY-MM-DD") + "T23:59:59Z"
      };
    };

    const dateText = val => dayjs(val).format("DD-MMM-YYYY HH:mm:ss");

    const numberWithCommas = val => helper.numberWithCommas(val);

    const updateContents = () => {
      const payload = { ...getDateRange(date.value) };
      const params = { page: 1, size: 100, manage: commentStatus.value };
      getContents({
        payload,
        params
      });
      getContentInfo(payload);
    };

    const handleStatus = status => {
      commentStatus.value = status;
      updateContents();
    };

    updateContents();

    return {
      ...toRefs(contentsState),
      dateText,
      numberWithCommas,
      calendarOptions,
      date,
      updateContents,
      handleStatus
    };
  }
});
</script>
