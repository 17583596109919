<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <!-- <img
              alt="Midone Tailwind HTML Admin Template"
              class="w-6"
              :src="require(`@/assets/images/logo.svg`)"
            /> -->
            <!-- <span class="text-white text-lg ml-3">
              Mid<span class="font-medium">One</span>
            </span> -->
            <span class="text-white text-lg ml-3">
              Gottago <span class="font-medium">Admin</span>
            </span>
          </a>
          <div class="my-auto">
            <!-- <img
              alt="Midone Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign in to your account.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your e-commerce accounts in one place
            </div> 
            -->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              gottago accounts in one place
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="loginForm.email"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
                @keydown.enter="doLogin"
              />
              <input
                v-model="loginForm.password"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Password"
                @keydown.enter="doLogin"
              />
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                @click="doLogin"
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import cookie from "js-cookie";
import { apiApp } from "/src/config/api";
import Swal from "sweetalert2";
export default defineComponent({
  // components: {
  //   DarkModeSwitcher
  // },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },
  data() {
    return {
      loadPage: false,
      loginForm: { email: "", password: "" }
    };
  },
  mounted() {},
  methods: {
    async doLogin() {
      try {
        const { email, password } = this.loginForm;
        await this.$firebase()
          .auth()
          .signInWithEmailAndPassword(email, password);
        const { token, claims } = await this.$firebase()
          .auth()
          .currentUser.getIdTokenResult();

        if (claims.role_id === 1 || !claims.role_id) {
          await this.$firebase()
            .auth()
            .signOut();
          delete apiApp.defaults.headers.common["Authorization"];
          cookie.remove("access_token");
          Swal.fire({
            icon: "error",
            html: `
            <div>
              ไม่มีสิทธิ์เข้าถึง กรุณาติดต่อผู้ดูแลระบบ
            </div>
            `,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#fe6f61"
          });
          return;
        }
        cookie.set("access_token", token, {
          path: "/",
          expires: 1
        });
        apiApp.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.token}`;
        this.$router.push({ path: "/" });
      } catch (error) {
        console.log("error: ", error);
        if (error.code) {
          let text = "";
          switch (error.code) {
            case "auth/invalid-email":
              text = "รูปแบบอีเมล์ไม่ถูกต้อง";
              break;
            case "auth/wrong-password":
              text = "Username หรือ Password ไม่ถูกต้อง กรุณาตรวจสอบ";
              break;
            case "auth/too-many-requests":
              text = "จำนวนร้องขอมากเกินไป กรุณารอสักครู่";
              break;
            default:
              break;
          }
          Swal.fire({
            icon: "error",
            text,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#fe6f61"
          });
        }
      }
    }
  }
});
</script>
