import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";

import Login from "../views/login/Main.vue";
import Logout from "../views/logout/Main.vue";
import Register from "../views/register/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import UpdateProfile from "../views/update-profile/Main.vue";
import ChangePassword from "../views/change-password/Main.vue";

import Dashboard from "../views/dashboard/Main.vue";
import UserManagement from "../views/user/Main.vue";
import UserDetail from "../views/user/_id/Detail.vue";
import UserReported from "../views/user/_id/Reported.vue";
import UserSuspension from "../views/user/_id/Suspension.vue";
import ContentManagement from "../views/content/Main.vue";
import ConteentManagementId from "../views/content/_id.vue";
import CommentMangement from "../views/comment/Main.vue";
import Agodamenegement from "../views/agoda-management/AgodaManagement.vue";

import cookie from "js-cookie";

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "dasboard",
        name: "side-menu-dashboard",
        component: Dashboard
      },
      {
        path: "user-management",
        name: "side-menu-user-management",
        component: UserManagement
      },
      {
        path: "user-management/:id/detail",
        name: "user-management-detail",
        component: UserDetail
      },
      {
        path: "user-management/:id/reported",
        name: "user-management-reported",
        component: UserReported
      },
      {
        path: "user-management/:id/suspension",
        name: "user-management-suspension",
        component: UserSuspension
      },

      {
        path: "content-management",
        name: "side-menu-content-management",
        component: ContentManagement
      },
      {
        path: "content-management/:id",
        name: "content-mangement-id",
        component: ConteentManagementId
      },
      {
        path: "comment-management",
        name: "side-menu-comment-management",
        component: CommentMangement
      },
      {
        path: "agoda-management",
        name: "agoda-management",
        component: Agodamenegement
      },
      {
        path: "/",
        name: "side-menu-dashboard",
        component: Dashboard
      },

      {
        path: "update-profile",
        name: "side-menu-update-profile",
        component: UpdateProfile
      },
      {
        path: "change-password",
        name: "side-menu-change-password",
        component: ChangePassword
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  console.log(to.name);
  let accessToken = cookie.get("access_token");
  if (
    to.name !== "login" &&
    !(accessToken !== undefined && accessToken !== null)
  )
    next({ name: "login" });
  else next();
});

export default router;
