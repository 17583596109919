<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- Dashboard -->
    <div class="col-span-12 xxl:col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">General Report</h2>
            <button
              class="ml-auto flex text-theme-1 dark:text-theme-10"
              @click="update"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </button>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <UserIcon class="report-box__icon text-theme-9" />
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ numberWithCommas(dashboard.user) }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    ผู้ใช้งานทั้งหมด
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <FileTextIcon class="report-box__icon text-theme-11" />
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ numberWithCommas(dashboard.content) }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">บทความทั้งหมด</div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <MonitorIcon class="report-box__icon text-theme-12" />
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ numberWithCommas(dashboard.view) }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">
                    ยอดรับชมทั้งหมด
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <ShareIcon class="report-box__icon text-theme-1" />
                  </div>
                  <div class="text-3xl font-bold leading-8 mt-6">
                    {{ numberWithCommas(dashboard.share) }}
                  </div>
                  <div class="text-base text-gray-600 mt-1">ยอดแชร์ทั้งหมด</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Dashboard -->
    <!-- Latest publish content -->
    <div
      class="col-span-12 xxl:col-span-12 flex flex-row justify-between mt-8 border-t border-b border-gray-400 py-4"
    >
      <div class="flex flex-row justify-start">
        <div class="flex flex-col sm:flex-row items-center">
          <h2 class="text-lg font-medium truncate mr-auto">
            บทความเผยแพร่ล่าสุด
          </h2>
        </div>
      </div>
    </div>
    <div
      v-if="latestPublishContentList.length < 1"
      class="col-span-12 h-12 flex items-center justify-center text-2xl"
    >
      ไม่พบบทความ
    </div>
    <div
      v-for="(item, index) in latestPublishContentList"
      :key="index"
      class="intro-y col-span-12 md:col-span-6 xl:col-span-3"
    >
      <div class="box zoom-in" @click="openContentPage(item.content_id)">
        <div class="p-2">
          <div class="h-36 image-fit">
            <img class="rouded-md" :src="item.image_url" />
          </div>
          <div class="text-base font-medium truncate mt-2">
            {{ item.content_name }}
          </div>
          <div class="text-gray-700 mt-2">
            <div>วันที่เผยแพร่: {{ dateText(item.created_at) }}</div>
            <div>ยอดไลค์: {{ numberWithCommas(item.likes) }}</div>
            <div>ยอดรับชม: {{ numberWithCommas(item.views) }}</div>
            <div>ความคิดเห็น: {{ numberWithCommas(item.comment) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Latest publish content -->
    <!-- Popular content -->
    <div
      class="col-span-12 xxl:col-span-12 flex flex-row justify-between mt-8 border-t border-b border-gray-400 py-4"
    >
      <div class="flex flex-row justify-start">
        <div class="flex flex-col sm:flex-row items-center">
          <h2 class="text-lg font-medium truncate mr-auto">
            บทความยอดนิยมสูงสุด
          </h2>
        </div>
        <div class="relative w-60 mx-5">
          <div
            class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
          >
            <CalendarIcon class="w-4 h-4" />
          </div>
          <Litepicker
            v-model="contentDate"
            :options="calendarOptions"
            class="form-control pl-12 w-50 block mx-auto"
            disable-default
            @change="updatePopularContent"
          />
        </div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              contentDate = '';
              updatePopularContent();
            }
          "
        >
          รีเซ็ต
        </button>
      </div>
      <select
        id="tabulator-html-filter-field"
        v-model="contentType"
        class="form-select sm:w-32 xxl:w-56"
        @change="updatePopularContent"
      >
        <option value="like">ยอดไลค์สูงสุด</option>
        <option value="share">ยอดแชร์สูงสุด</option>
        <option value="view">ยอดรับชมสูงสุด</option>
      </select>
    </div>
    <div
      v-if="popularContentList.length < 1"
      class="col-span-12 h-12 flex items-center justify-center text-2xl"
    >
      ไม่พบบทความ
    </div>
    <div
      v-for="(item, index) in popularContentList"
      :key="index"
      class="intro-y col-span-12 md:col-span-6 xl:col-span-3"
    >
      <div class="box zoom-in" @click="openContentPage(item.content_id)">
        <div class="p-2">
          <div class="h-36 image-fit">
            <img class="rouded-md" :src="item.image_url" />
          </div>
          <div class="text-base font-medium truncate">
            <template v-if="contentType === 'like'">
              {{ numberWithCommas(item.likes) }} Like
            </template>
            <template v-else-if="contentType === 'share'">
              {{ numberWithCommas(item.shares) }} Share
            </template>
            <template v-else-if="contentType === 'view'">
              {{ numberWithCommas(item.views) }} View
            </template>
          </div>
          <div class="text-gray-700">
            <div class="text">ชื่อบทความ: {{ item.content_name }}</div>
            <div>วันที่สร้าง: {{ dateText(item.created_at) }}</div>
            <div>ยอดไลค์: {{ numberWithCommas(item.likes) }}</div>
            <div>ยอดรับชม: {{ numberWithCommas(item.views) }}</div>
            <div>ความคิดเห็น: {{ numberWithCommas(item.comment) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Popular content -->
    <!-- Popular user -->
    <div
      class="col-span-12 xxl:col-span-12 flex flex-row justify-between mt-8 border-t border-b border-gray-400 py-4"
    >
      <div class="flex flex-row justify-start">
        <div class="flex flex-col sm:flex-row items-center">
          <h2 class="text-lg font-medium truncate mr-auto">
            ผู้ใช้งานยอดนิยม
          </h2>
        </div>
        <div class="relative w-60 mx-5">
          <div
            class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
          >
            <CalendarIcon class="w-4 h-4" />
          </div>
          <Litepicker
            v-model="userDate"
            :options="calendarOptions"
            class="form-control pl-12 w-50 block mx-auto"
            disable-default
            @change="updatePopularUser"
          />
        </div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              userDate = '';
              updatePopularUser();
            }
          "
        >
          รีเซ็ต
        </button>
      </div>
    </div>
    <div
      v-if="popularUserList.length < 1"
      class="col-span-12 h-12 flex items-center justify-center text-2xl"
    >
      ไม่พบผู้ใช้งาน
    </div>
    <div
      v-for="(item, index) in popularUserList"
      :key="index"
      class="intro-y col-span-12 md:col-span-6 xl:col-span-3"
    >
      <div class="box zoom-in" @click="openProfilePage(item.uid)">
        <div class="p-2">
          <div class="h-36 image-fit">
            <img class="rouded-md" :src="item.image_url" />
          </div>
          <div class="text-base font-medium truncate">
            {{ numberWithCommas(item.follows) }} Follower
          </div>
          <div class="text-gray-700">
            <div class="text">ชื่อโปรไฟล์: {{ item.profile }}</div>
            <div>บทความทั้งหมด: {{ item.contents }}</div>
            <div>ยอดไลค์: {{ item.likes }}</div>
            <div>ยอดรับชม: {{ item.views }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Popular user -->
    <!-- Popular mood -->
    <div
      class="col-span-12 xxl:col-span-12 flex flex-row justify-between mt-8 border-t border-b border-gray-400 py-4"
    >
      <div class="flex flex-row justify-start">
        <div class="flex flex-col sm:flex-row items-center">
          <h2 class="text-lg font-medium truncate mr-auto">
            Mood ยอดนิยมสูงสุด
          </h2>
        </div>
        <div class="relative w-60 mx-5">
          <div
            class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
          >
            <CalendarIcon class="w-4 h-4" />
          </div>
          <Litepicker
            v-model="moodDate"
            :options="calendarOptions"
            class="form-control pl-12 w-50 block mx-auto"
            disable-default
            @change="updatePopularMood"
          />
        </div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              moodDate = '';
              updatePopularMood();
            }
          "
        >
          รีเซ็ต
        </button>
      </div>
    </div>
    <template v-if="popularMoodList.length < 1">
      <div class="col-span-12 h-12 flex items-center justify-center text-2xl">
        ไม่พบ Mood
      </div>
    </template>
    <template v-else>
      <div class="col-span-12">
        <div class="grid grid-cols-2 gap-2 intro-y items-start">
          <div class="grid grid-cols-3 item-center box h-56 items-center">
            <template v-for="(item, index) in popularMoodList">
              <div v-if="index < 3" :key="index" class="p-5 text-center">
                <h2 class="text-4xl font-medium leading-none">
                  {{ item.amount }}
                </h2>
                <h4 class="text-xl font-medium leading-none mt-3">
                  {{ item.name }}
                </h4>
              </div>
            </template>
          </div>
          <div class="font-lg intro-y">
            <template v-for="(item, index) in popularMoodList">
              <h5 v-if="index > 2" :key="index" class="box p-2 mb-2">
                {{ item.name }} ({{ item.amount }})
              </h5>
            </template>
          </div>
        </div>
      </div>
    </template>
    <!-- /Popular mood -->
    <!-- Popular tag -->
    <div
      class="col-span-12 xxl:col-span-12 flex flex-row justify-between mt-8 border-t border-b border-gray-400 py-4"
    >
      <div class="flex flex-row justify-start">
        <div class="flex flex-col sm:flex-row items-center">
          <h2 class="text-lg font-medium truncate mr-auto">
            แท็กยอดนิยม 10 อันดับ
          </h2>
        </div>
        <div class="relative w-60 mx-5">
          <div
            class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
          >
            <CalendarIcon class="w-4 h-4" />
          </div>
          <Litepicker
            v-model="tagDate"
            :options="calendarOptions"
            class="form-control pl-12 w-50 block mx-auto"
            disable-default
            @change="updatePopularTag"
          />
        </div>
        <button
          class="btn btn-secondary"
          @click="
            () => {
              tagDate = '';
              updatePopularTag();
            }
          "
        >
          รีเซ็ต
        </button>
      </div>
    </div>
    <template v-if="popularTagList.length < 1">
      <div class="col-span-12 h-12 flex items-center justify-center text-2xl">
        ไม่พบ Tag
      </div>
    </template>
    <template v-else>
      <div class="col-span-12">
        <div class="grid grid-cols-2 gap-2 intro-y items-start">
          <div class="grid grid-cols-3 item-center box h-56 items-center">
            <template v-for="(item, index) in popularTagList">
              <div v-if="index < 3" :key="index" class="p-5 text-center">
                <h2 class="text-4xl font-medium leading-none">
                  {{ item.amount }}
                </h2>
                <h4 class="text-xl font-medium leading-none mt-3">
                  {{ item.name }}
                </h4>
              </div>
            </template>
          </div>
          <div class="font-lg intro-y">
            <template v-for="(item, index) in popularTagList">
              <h5 v-if="index > 2" :key="index" class="box p-2 mb-2">
                {{ item.name }} ({{ item.amount }})
              </h5>
            </template>
          </div>
        </div>
      </div>
    </template>

    <!-- /Popular tag -->
  </div>
</template>
<script>
import { defineComponent, ref, toRefs, reactive } from "vue";
import { clientHost } from "@/config/api";
import useDashboard from "/src/compositions/dashboard/useDashboard";
import usePopularContent from "/src/compositions/dashboard/usePopularContent";
import usePopularUser from "/src/compositions/dashboard/usePopularUser";
import usePopularMood from "/src/compositions/dashboard/usePopularMood";
import usePopularTag from "/src/compositions/dashboard/usePopularTag";
import useLatestPublishContent from "/src/compositions/dashboard/useLatestPublishContent";
import { helper } from "@/utils/helper";
import dayjs from "dayjs";

export default defineComponent({
  name: "Dashboard",
  setup() {
    const calendarOptions = reactive({
      autoApply: false,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      showWeekNumbers: true,
      dropdowns: {
        minYear: 1990,
        maxYear: null,
        months: true,
        years: true
      }
    });

    const contentDate = ref("");
    const contentType = ref("like");
    const userDate = ref("");
    const moodDate = ref("");
    const tagDate = ref("");

    const { state: dashboardState, getDashboard } = useDashboard();

    const {
      state: popularContentState,
      getPopularContent
    } = usePopularContent();

    const { state: popularUserState, getPopularUser } = usePopularUser();

    const { state: popularMoodState, getPopularMood } = usePopularMood();

    const { state: popularTagState, getPopularTag } = usePopularTag();

    const {
      state: latestPublishContent,
      getLatestPublishContent
    } = useLatestPublishContent();

    const getDateRange = dateString => {
      if (!dateString) return {};
      const [beg_date, end_date] = dateString.split("-");
      return {
        beg_date: dayjs(beg_date).format("YYYY-MM-DD") + "T00:00:00Z",
        end_date: dayjs(end_date).format("YYYY-MM-DD") + "T23:59:59Z"
      };
    };

    const numberWithCommas = val => helper.numberWithCommas(val);

    const dateText = val => dayjs(val).format("DD-MMM-YYYY HH:mm:ss");

    const updatePopularContent = () => {
      getPopularContent({
        ...getDateRange(contentDate.value),
        max: 8,
        type: contentType.value
      });
    };

    const updatePopularUser = () => {
      getPopularUser({
        ...getDateRange(userDate.value),
        max: 8
      });
    };

    const updatePopularMood = () => {
      getPopularMood({
        ...getDateRange(moodDate.value),
        max: 10
      });
    };

    const updatePopularTag = () => {
      getPopularTag({
        ...getDateRange(tagDate.value),
        max: 10
      });
    };

    const openContentPage = id => {
      open(clientHost + "/feed/" + id, "_blank");
    };

    const openProfilePage = id => {
      open(clientHost + "/profile/" + id, "_blank");
    };

    const update = () => {
      getDashboard();
      updatePopularContent();
      updatePopularUser();
      updatePopularMood();
      updatePopularTag();
      getLatestPublishContent();
    };

    update();
    return {
      calendarOptions,
      numberWithCommas,
      dateText,
      update,
      updatePopularContent,
      updatePopularUser,
      updatePopularMood,
      updatePopularTag,
      ...toRefs(dashboardState),
      ...toRefs(popularContentState),
      ...toRefs(popularUserState),
      ...toRefs(popularMoodState),
      ...toRefs(popularTagState),
      ...toRefs(latestPublishContent),
      contentDate,
      contentType,
      userDate,
      moodDate,
      tagDate,
      openContentPage,
      openProfilePage
    };
  }
});
</script>

<style scoped>
.text {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
