import { reactive } from "vue";
import * as services from "/src/services/dashboard";

const initState = {
  dashboard: {
    content: 0,
    report: 0,
    share: 0,
    user: 0,
    view: 0
  }
};

export default function useDashboard() {
  const state = reactive(initState);

  const getDashboard = async () => {
    const result = await services.getOverallDashboard();
    state.dashboard = Object.assign(
      state.dashboard,
      result?.data?.data?.dashboard || {}
    );
  };

  return {
    state,
    getDashboard
  };
}
