import { reactive } from "vue";

export default function usePagination(
  totalItems = 0,
  currentPage = 1,
  pageSize = 5,
  maxPages = 5
) {
  const state = reactive({
    totalItems,
    currentPage,
    pageSize,
    maxPages,
    totalPages: 0,
    startPage: 0,
    endPage: 0,
    startIndex: 0,
    endIndex: 0,
    pages: []
  });

  const setPage = pageNumber => {
    state.currentPage = pageNumber;
    handlePagination();
  };

  const handlePagination = () => {
    state.totalPages = Math.ceil(state.totalItems / state.pageSize);
    if (state.currentPage < 1) {
      state.currentPage = 1;
    } else if (state.currentPage > state.totalPages) {
      state.currentPage = state.totalPages;
    }

    if (state.totalPages <= state.maxPages) {
      state.startPage = 1;
      state.endPage = state.totalPages;
    } else {
      let maxPagesBeforeCurrentPage = Math.floor(state.maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(state.maxPages / 2) - 1;
      if (state.currentPage <= maxPagesBeforeCurrentPage) {
        state.startPage = 1;
        state.endPage = state.maxPages;
      } else if (
        state.currentPage + maxPagesAfterCurrentPage >=
        state.totalPages
      ) {
        state.startPage = state.totalPages - state.maxPages + 1;
        state.endPage = state.totalPages;
      } else {
        state.startPage = state.currentPage - maxPagesBeforeCurrentPage;
        state.endPage = state.currentPage + maxPagesAfterCurrentPage;
      }
    }

    state.startIndex = (state.currentPage - 1) * state.pageSize;
    state.endIndex = Math.min(
      state.startIndex + state.pageSize - 1,
      state.totalItems - 1
    );
    state.pages = Array.from(
      Array(state.endPage + 1 - state.startPage).keys()
    ).map(i => state.startPage + i);
  };

  return {
    state,
    setPage,
    handlePagination
  };
}
