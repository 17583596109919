<template>
  <div>
    <div class="text-lg font-medium pt-2">
      <a href="javascript:;" @click="goBack"
        ><ChevronLeftIcon class="w-6 h-6 mr-1" />กลับ</a
      >
    </div>
    <div class="intro-y flex justify-between items-center my-3">
      <div class="flex items-center space-x-3">
        <h2 class="text-lg font-medium">ประวัติการถูกรายงาน</h2>
        <div>
          <img
            alt="img"
            class="rounded-full object-cover object-center h-10 w-10"
            :src="getUserImg(user.User?.image_url)"
          />
        </div>
        <div class="flex space-x-3">
          <div>{{ user.User?.first_name }}</div>
          <div>{{ user.User?.last_name }}</div>
        </div>
      </div>
      <div class="flex space-x-3">
        <div class="btn  btn-warning text-white " @click="setModal">
          ส่งแจ้งเตือน
        </div>
        <div class="btn btn-danger " @click="confirmSuspend">
          ระงับการใช้งาน
        </div>
      </div>
    </div>
    <div v-if="!items.length" class="intro-y box p-5 text-center text-2xl">
      <div>ไม่พบข้อมูล</div>
    </div>
    <div v-else class="intro-y box p-5">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="intro-y col-span-12 m-5"
      >
        <div class="box zoom-in p-5">
          <div class="flex flex-row justify-between items-center">
            <div>
              <div class="text-lg">
                {{ $h.formatDate(item.created_at, `[วันที่] D MMMM BBBB`) }}
              </div>
              <div class="text-lg">
                <span class=" font-bold">{{ getTypeText(item.type) }}</span>
                : {{ item.name }}
              </div>
              <div class="flex text-lg items-center space-x-3">
                <div>
                  ผู้รายงาน:
                </div>
                <div class="flex space-x-2">
                  <div>
                    <img
                      alt="img"
                      class="rounded-full object-cover object-center h-8 w-8"
                      :src="getUserImg(item.Reporter?.image_url)"
                    />
                  </div>
                  <div>
                    {{ item.Reporter?.name || "" }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <a
                class="btn btn-primary whitespace-nowrap"
                :href="clientHost + '/feed/' + item.content_id"
                target="_blank"
                >ดูรายละเอียด</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="alert-modal-user" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">แจ้งเตือนผู้ใช้งาน</div>
            </div>
            <div class="grid grid-cols-6 justify-items-center">
              <div class="col-start-2 col-span-4">
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานมีความก่อกวน"
                  />
                  <label class="form-check-label" for="radio-switch-1"
                    >ผู้ใช้งานมีความก่อกวน</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานมีความยุยงให้เกิดความขัดแย้ง"
                  />
                  <label class="form-check-label" for="radio-switch-2"
                    >ผู้ใช้งานมีความยุยงให้เกิดความขัดแย้ง</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-3"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="ผู้ใช้งานสนับสนุนความรุนแรง"
                  />
                  <label class="form-check-label" for="radio-switch-3"
                    >ผู้ใช้งานสนับสนุนความรุนแรง</label
                  >
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-4"
                    v-model="state.alert_choice"
                    class="form-check-input"
                    type="radio"
                    value="other"
                  />
                  <label class="form-check-label" for="radio-switch-4"
                    >อื่นๆ</label
                  >
                </div>
                <div class="mt-4 flex items-center border border-gray-200">
                  <textarea
                    v-model="state.alert_comment"
                    class="chat__box__input form-control dark:bg-dark-3 h-20 resize-none border-transparent p-2 shadow-none focus:ring-0"
                    rows="1"
                    placeholder="เหตุผลการแจ้งเตือน..."
                    :disabled="disabled"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="p-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
                @click="
                  () => {
                    state.alert_choice = 'บทความไม่เหมาะสม';
                    state.alert_comment = '';
                  }
                "
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24  "
                data-dismiss="modal"
                @click="confirmReportUser"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="confirm-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-2xl mt-5">
                คุณต้องการระงับการใช้งานผู้ใช้ใช่หรือไม่
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                data-dismiss="modal"
                @click="suspendUserHandle"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as userService from "/src/services/user";
import defaultPeople from "@/assets/images/blank_user.png";
import Swal from "sweetalert2";
import { clientHost } from "@/config/api";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    let user = ref({
      follower: 0,
      User: {
        role_id: 1
      }
    });
    const state = reactive({
      alert_comment: "",
      alert_choice: "ผู้ใช้งานมีความก่อกวน"
    });

    const disabled = ref(false);
    const items = ref([]);

    watch(state, val => {
      if (val.alert_choice !== "other") {
        disabled.value = true;
        val.alert_comment = "";
      } else {
        disabled.value = false;
      }
    });

    const setModal = () => {
      cash("#alert-modal-user").modal("show");
    };

    const confirmReportUser = async () => {
      try {
        const { id } = route.params;
        const data = {
          remark: state.alert_choice ? state.alert_choice : state.alert_comment,
          Type: "User"
        };
        await userService.reportUser(id, data);

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "แจ้งเตือนสำเร็จ"
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.response.data.error) {
          const errorMessage = error.response.data.error.error_message;
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            title: errorMessage
          });
        }
      }
    };

    const confirmSuspend = () => {
      cash("#confirm-modal").modal("show");
    };

    const suspendUserHandle = async () => {
      try {
        const { id } = route.params;
        const data = {
          user_uid: id,
          status: 2
        };
        await userService.susppendUser(data);

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title: "ระงับผู้ใช้งานสำเร็จ"
        });
        router.go(-1);
      } catch (error) {
        console.log("error: ", error);
        if (error.response.data.error) {
          const errorMessage = error.response.data.error.error_message;
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            title: errorMessage
          });
        }
      }
    };

    const getUserImg = image => {
      return image === "" ? defaultPeople : image;
    };

    const getTypeText = type => {
      switch (type) {
        case "Comment":
          return "รายงานความคิดเห็น";
        case "User":
          return "รายงานผู้ใช้งาน";
        case "Content":
          return "รายงานบทความ";
        default:
          return "";
      }
    };

    const goBack = () => {
      router.go(-1);
    };

    const fetch = async () => {
      const { id } = route.params;
      const res = await userService.getUserReported(id);
      const res_user = await userService.getUser(id);
      user.value = res_user.data.data.user_management;
      items.value = res.data.data.user_management;
    };

    onMounted(() => {
      fetch();
    });
    return {
      user,
      getUserImg,
      goBack,
      state,
      setModal,
      confirmReportUser,
      suspendUserHandle,
      confirmSuspend,
      items,
      getTypeText,
      disabled,
      clientHost
    };
  }
};
</script>

<style lang="scss" scoped></style>
